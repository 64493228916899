<script setup lang="ts">
    declare interface ProducPriceProps {
        productPriceLabel?: string | null;
        price?: string | null;
        compareAtPrice?: string | null;
        displayPriceOnly?: boolean;
        showBorder?: boolean;
        stacked?: boolean;
        alignment?: 'center' | 'right';
        size?: 'md' | 'lg';
    }

    defineProps<ProducPriceProps>();

    const { isInternational } = useLocales();
</script>
<template>
    <div :class="['flex flex-col gap-2 font-mono', { 'border-t border-solid border-woom-grey-mid pt-6': showBorder }]">
        <atm-product-price-label
            :product-price-label="productPriceLabel"
            :class="[{ hidden: displayPriceOnly }]" />
        <div
            v-if="isInternational"
            :class="[{ hidden: displayPriceOnly }]">
            <a
                :href="$t('price.plusCustomFeesLink')"
                target="_blank">
                {{ $t('price.plusCustomFees') }}
            </a>
        </div>

        <div
            :class="[
                'flex gap-1',
                { 'items-center justify-center': alignment === 'center', ' items-end justify-end': alignment === 'right', 'flex-col': stacked },
            ]">
            <span
                :class="[
                    'font-sans font-bold',
                    {
                        'text-2xl md:text-3xl': size === 'lg',
                        'text-lg leading-5': size === 'md',
                    },
                ]">
                {{ price }}
            </span>
            <span
                class="line-through"
                v-if="compareAtPrice">
                <span class="sr-only">{{ $t('price.a11y.strikeprice') }}</span>
                {{ compareAtPrice }}
            </span>
        </div>
    </div>
</template>
